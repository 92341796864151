import React from 'react';

const About = () => {
    return (
        <section className="About__card">
            <h2 className="About__title">Simeon Parris</h2>
            <h3 className="About__subtitle">Junior Web Developer</h3>
            <section className="About__text-and-pic">
                <img className="About__profile-pic" src={require(`../images/simeon-profile-pic.jpg`)} alt="Me looking a little awkward." />
                <section className="About__profile-text">
                    <p className="About__text">Although new to the world of web development, I am keen to enhance my range of skills and become a senior developer in the coming years. Eventually I wish to use my knowledge of teaching methodologies to pass on what I have learnt by mentoring up-and-coming new developers, as well as contributing to open source projects.</p>
                    <p className="About__text">I have a varied skillset due to working both overseas and in the UK. I was  an English language teacher in China, the UK and the UAE for over twelve years. Throughout this time I developed a keen attention to detail and a strong work ethic. Many of the skills from this profession are directly transferable to coding and web development. Testing and feedback helped me deliver higher quality lessons to my students and this same kind of cycle has been deeply schooled in me through my time at Northcoders.</p>
                    <p className="About__text">I am currently working hard to deepen my understanding of web related technologies. After seeing some of the interesting apps my classmates created using Dart and Flutter for their final projects, I decided to learn about this language and framework. Although quite different from Javascript and React, which I studied on the course, I was able to create a Flutter app to make organized shopping lists from the ingredients of my favourite dishes. Although I have reached what I set out as my minimum viable product, this is an ongoing project and I plan to add more features, including the ability to save / retrieve lists on the users device as well as integration with a Firebase database which stores additional meals.</p>
                    <p className="About__text">The next challenges of interest are learning to code in C# and PHP, as they both appear to be in high demand by employers. I am studying C# by following a course on game development and am making a text adventure. To explore PHP I am planning to use it to build a website for a small wedding videography business which employs the use of virtual reality cameras.</p>
                </section>
            </section>

        </section>
    );
};

export default About;