import React from 'react';
import '../App.css';

const Header = () => {
    return (
        <header >
            <h1 className="site__title">SimeonParris.com</h1>
        </header>
    );
};

export default Header;