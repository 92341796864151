const projectData = [
    {
        projectName: 'Meal2List',
        projectType: 'Flutter App',
        projectTeam: 'Just me',
        projectDates: 'June / July 2020',
        projectLocation: 'Remote Working in Harrogate, UK',
        projectTech: 'Dart & Flutter',
        projectDetail: 'This project is the first that I embarked upon after completing the Northcoders Developer Pathway Course. I set out to learn how to create an app using Flutter & Dart and this then evolved into a simple application to aleviate one of my pet peeves; writing shopping lists.',
        projectDetail2: 'I have currently achieved my minimum viable product for this app but this will be an ongoing project with new features and content added over time. The app allows the user to select meals from a fixed list and from there a list of all the required ingredients is formed. This then gives the user the opportunity to remove items from the list that they already have before finalizing the shopping list. The final screen is for use while shopping and it displays all the items for purchase, grouped by the area of the shop they are in. The list allows the user to remove items and tells them how many items are left to get.',
        projectDetail3: 'For the best experience of the web-based demo version of this app, please use Google Chrome.',
        projectFrontEndLink: 'https://www.meal2list.com',
        projectBackEndLink: '',
        projectFrontEndRepo: 'https://github.com/PsimanUK/meal2list_v2',
        projectBackEndRepo: '',
        projectImage: 'meal2list-on-ios.png',
        projectVideo: 'https://www.youtube.com/embed/YpmGb-YMKDs',
        projectVideoTitle: 'Meal 2 List App Demonstration',
    },
    {
        projectName: 'Independent Leeds',
        projectType: 'React App',
        projectTeam: 'JJJS (Myself and three other students)',
        projectDates: 'May 2020',
        projectLocation: 'Remote Working in Harrogate, UK',
        projectTech: 'React, Material-UI, Leaflet, AWS Auth, AWS Lambda, AWS DynamoDB, Javascript',
        projectDetail: 'This project represented the final stage of my Northcoders course. I worked in a team with three other Northcoders students using agile practices, which included twice daily virtual stand up meetings to discuss progress and make plans based on the state of the project. We reacted quickly by researching concepts and then spiking them to see if we could achieve the desired outcome.',
        projectDetail2: 'The goal of this project was to develop a proof of concept for progressive web app which would allow local small businesses and potential customers to connect while the lockdown was taking place. The app was designed to allow users to create a listing for their business and share relevant information such as location, special offers and any changes or restrictions with potential customers. All business are displayed on a responsive map the front page of the site, which also populates data to cards below and their number corresponds to the the visable pins on the map as it is zoomed in and out.',
        projectDetail3: 'The app was built with a React front end and uses Leaflet, an open source alternative to Google Maps. The backend uses Amazon AWS services such as Auth, Lambda and DynamoDB for authentication and data storage.',
        projectFrontEndLink: 'https://independent-leeds.netlify.app',
        projectBackEndLink: '',
        projectFrontEndRepo: 'https://github.com/PsimanUK/independent-leeds',
        projectBackEndRepo: '',
        projectImage: 'indie-leeds.png',
        projectVideo: 'https://www.youtube.com/embed/IEPAdmczvNo',
        projectVideoTitle: 'Demonstration of Independent Leeds App',
    },
    {
        projectName: 'SP-News',
        projectType: 'React App',
        projectTeam: 'Me with support from Northcoders tutors',
        projectDates: 'April 2020',
        projectLocation: 'Remote Working in Harrogate, UK',
        projectTech: 'React, Axios, Express, PostgreSQL, Javascript, Jest, Moment',
        projectDetail: 'This project was completed within the module reviews of the front end and back end modules of the Northcoders Developer Pathway Course. The app is a basic news hosting site which allows users to sort, read and interact with articles via comments and voting.',
        projectDetail2: 'The front end was built using React and Axios. The back end was built using Javascript and Express. Please see the links for the hosting links and GitHub repos.',
        projectDetail3: '',
        projectFrontEndLink: 'https://sp-news.netlify.app',
        projectBackEndLink: '',
        projectFrontEndRepo: 'https://github.com/PsimanUK/sp-news',
        projectBackEndRepo: 'https://github.com/PsimanUK/be-review-news',
        projectImage: 'sp-news.png',
        projectVideo: 'https://www.youtube.com/embed/OBn3DpCG1vw',
        projectVideoTitle: 'Demonstration of SP News Website',
    },
];

export default projectData;