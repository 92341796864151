const resumeData = [
    {
        jobType: 'web',
        company: 'Post Course Independent Study',
        jobTitle: 'Student',
        jobDates: 'June 2020 - PRESENT',
        jobLocation: 'Harrogate, UK',
        jobDetail: 'Working on independent projects to expand my skillset and learn further languages and technologies to make me a stronger candidate for employment. Currently building an app using Flutter/Dart which takes some of my favourite meals and produces shopping lists from the ingredients. Recently started studying C# and PHP as these appear to be common requirements for web developer roles. A link to a web-hosted version of the Flutter app can be found in the projects section of this website.',
    },
    {
        jobType: 'web',
        company: 'Northcoders',
        jobTitle: 'Student',
        jobDates: 'February 2020 - June 2020',
        jobLocation: 'Leeds, UK',
        jobDetail: 'Studied a wide variety of web technologies as part of the Northcoders Full Stack Web Developer Pathway. This course has an industry-led curriculum and teaches best practices, which include version control using Git, TDD and pair programming. The daily schedule involved and hour of practice katas in the morning followed by lectures. Late morning and afternoons were filled with single-day or multi-day sprints. The examinations for the back and front end section of the course were to build an Express server with SQL database for a news website and a ReactJS front-end to interact with it. The final stage of the course consisted of a group project where I collaborated with three other students to produce a mock-up of a website to support Leeds-based local businesses who were in, or just coming out of, lockdown. Links to both of these websites can be found in the projects section of this portfolio website.',
    },
    {
        jobType: 'other',
        company: 'Career Break​',
        jobTitle: '​Travelling and Personal Development',
        jobDates: 'January 2019 - January 2020',
        jobLocation: 'Various Locations, UK',
        jobDetail: 'Travelled mostly in the UK but also visited Shanghai for a few weeks. In this time I worked on developing my skills as a videographer and explored a business opportunity in virtual reality video making, which unfortunately did not progress.',
    },
    {
        jobType: 'teaching',
        company: 'British Council',
        jobTitle: 'English Teacher',
        jobDates: 'May 2015 - December 2018',
        jobLocation: 'Sharjah, UAE',
        jobDetail: 'Trained skill levels from beginner to intermediate and ages from 6 to 60+ in written and spoken English communication. Created tests and digital materials based on provided curricula. Used Activ Inspire software to create digital flipcharts for Promethean interactive whiteboards. I also provided support and training for newly qualified teachers.',
    },
    {
        jobType: 'teaching',
        company: 'ITTC',
        jobTitle: 'DELTA Student',
        jobDates: 'Sept 2014 - Dec 2014',
        jobLocation: 'Bournemouth, UK',
        jobDetail: 'This Diploma in English Language Teaching for Adults is a QCF level 7 course and is equivalent to one third of an MSc in English Language Teaching. Passed with a distinction in the Developing Professional Practice module, which was only attained by four percent of candidates.',
    },
    {
        jobType: 'teaching',
        company: 'JiaXiang Primary, High and International Schools',
        jobTitle: 'English Teacher',
        jobDates: 'October 2012 - July 2014',
        jobLocation: 'Chengdu, China',
        jobDetail: 'Delivered interactive, communicative and student centered lessons to a wide variety of Chinese students ranging from 7 to 17 years of age with beginner to advanced skill levels. Aside from English teaching duties, I created and delivered cultural awareness and western history to the advanced level students in the international school.',
    },
    {
        jobType: 'teaching',
        company: 'Anglo European School of English',
        jobTitle: 'English Teacher',
        jobDates: 'March 2010 - August 2012',
        jobLocation: 'Bournemouth, UK',
        jobDetail: 'Provided interactive and engaging English lessons to mostly adult students from elementary to advanced skill levels. Developed materials for interactive white boards and planned lessons based on a limited syllabus, which allowed for a great deal of flexibility and autonomy.',
    },
    {
        jobType: 'other',
        company: 'The IET',
        jobTitle: 'Data Planner',
        jobDates: 'August 2008 - September 2009',
        jobLocation: 'Stevenage, UK',
        jobDetail: 'Souced lists of customers for the marketing team from the company database and external data companies. Maintained records of which lists were obtained and which marketing teams used them. Learnt the basics of database marketing.',
    },
    {
        jobType: 'teaching',
        company: 'Beijing Univeristy of Finance and Economics',
        jobTitle: 'English Language and Business English Teacher',
        jobDates: 'September 2007 - July 2008',
        jobLocation: 'Beijing, China',
        jobDetail: 'Taught written and spoken English to post graduate students as part of their master program. Delivered engaging and interactive Business English classes to PHD and MBA students.',
    },
    {
        jobType: 'teaching',
        company: 'Beijing University of Aeronautics and Astronautics',
        jobTitle: 'English Teacher',
        jobDates: 'September 2006 - July 2007',
        jobLocation: 'Beijing, China',
        jobDetail: 'Taught mostly undergraduate students in academic English writing. Was also involved in deliving a number of evening seminars on a wide range of western cutural topics.',
    },
    {
        jobType: 'teaching',
        company: 'Lingdong English School',
        jobTitle: 'English Teacher',
        jobDates: 'February 2005 - August 2006',
        jobLocation: 'Foshan, China',
        jobDetail: 'My first role as an English teacher and one in which I was taken from complete novice to competent English language teacher. The vast majority of students at this establishment were children but I did teach a couple of classes a week to local corporations.',
    },
    {
        jobType: 'other',
        company: 'LOGiCOM',
        jobTitle: 'Customer Service Data Analyst',
        jobDates: 'August 2001 - July 2004',
        jobLocation: 'Stevenage, UK',
        jobDetail: 'Started work as a member of the customer service team but was soon promoted to the role of data analyst due to my skills with Excel. Kept records and analysed KPIs to create reports and charts for use in all departments within the business.',
    },
    {
        jobType: 'other',
        company: 'ICL',
        jobTitle: 'First-Line IT Support',
        jobDates: 'January 2001 - July 2001',
        jobLocation: 'Stevenage, UK',
        jobDetail: 'Delivered first-line IT support via telephone to a wide range of ICLs clients including KPMG.',
    },
];

export default resumeData;