import React from 'react';
import projectData from '../data/projectData';
import ProjectCard from '../components/ProjectCard';


const Projects = () => {

    return (
        <section className="Projects">
            <p className="intro">Below you can find information about and links to the web development projects I have been, and am currently, working on.</p>
            {projectData.map(
                (project) => {

                    return <ProjectCard key={project.projectName} {...project} />;

                }
            )}
        </section>
    );
};

export default Projects;