import React from 'react';

const ResumeCard = ({ jobType, company, jobTitle, jobDates, jobLocation, jobDetail }) => {
    return (
        <section className="Resume__card">
            <h2>{company} / {jobTitle}</h2>
            <h3>{jobDates}</h3>
            <h3>{jobLocation}</h3>
            <p>{jobDetail}</p>
        </section>
    );
};

export default ResumeCard;