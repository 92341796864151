import React from 'react';
import { Router } from "@reach/router";
import { ThemeProvider } from "@material-ui/core";

import Header from './components/Header.jsx';
import NavBar from './components/NavBar.jsx';

import Landing from './components/Landing.jsx';
import Resume from './components/Resume.jsx';
import Projects from './components/Projects.jsx';
import Blog from './components/Blog.jsx';
import About from './components/About.jsx';

import './App.css';

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <Header />
        <NavBar />
        <Router primary={false}>
          <Landing path="/" />
          <Resume path="/resume" />
          <Projects path="/projects" />
          <Blog path="/blog" />
          <About path="/about" />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
