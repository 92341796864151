import React, { useState } from 'react';
import resumeData from '../data/resumeData'
import ResumeCard from './ResumeCard';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// USES A FUNCTION AND REACT HOOKS INSTEAD OF STATE

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(2),
        minWidth: 250,
        padding: 10,
        margin: 'auto',
    },
    select: {
        fontFamily: 'Rock Salt',
        fontSize: 12,
        width: 200,

    },
    inputLabel: {
        fontFamily: 'Rock Salt',
        fontSize: 12,
        marginLeft: 10,
        marginTop: 5,

    },
    menuItem: {
        fontFamily: 'Rock Salt',
        fontSize: 10,
    },

}));

function Resume() {


    const classes = useStyles();
    const [jobType, setJobType] = useState('');

    const handleJobTypeChange = (event) => {
        setJobType(event.target.value);
    }
    return (
        <section className="Resume">
            <p className="intro">Select a resumé section from the dropdown menu to see my work experience in that field.</p>
            <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="Resume_select_label" className={classes.inputLabel}>Resumé Section</InputLabel>
                <Select labelId="Resume_select_label" id="Resume_select" className={classes.select} value={jobType} onChange={handleJobTypeChange} label="Resume Section" displayEmpty>
                    <MenuItem className={classes.menuItem} value="web">Web Development</MenuItem>
                    <MenuItem className={classes.menuItem} value="teaching">Teaching</MenuItem>
                    <MenuItem className={classes.menuItem} value="other">Other</MenuItem>
                    <MenuItem className={classes.menuItem} value="chronological">Chronological</MenuItem>
                </Select>
            </FormControl>
            {resumeData.map(
                (job) => {
                    if (jobType === job.jobType) {
                        return <ResumeCard key={job.company} {...job} />;
                    } else if (jobType === 'chronological') {
                        return <ResumeCard key={job.company} {...job} />;
                    } else {
                        return null;
                    }
                }
            )}
        </section>
    );
}

export default Resume;