import React from 'react';

const Landing = () => {
    return (
        <section className="About__card">
            <h2 className="Landing__title">Welcome to my portfolio and resume website.</h2>
            <p className="Landing__text">I have recently graduated from the <a href="https://northcoders.com/developer-pathway">Northcoders Developer Pathway Course</a> and am now taking the initial steps on my career journey as web developer; looking for that all important first role.</p>
            <p className="Landing__text">Please take the time to view my resume, check out my project portfolio and read more about me.</p>
        </section>
    );
};

export default Landing;