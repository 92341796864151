import React from 'react';

const Blog = () => {
    return (
        <section className="Blog__card">
            <h2>Video blogs COMING SOON...</h2>
        </section>
    );
};

export default Blog;