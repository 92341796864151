import React, { Component } from "react";
import { Link } from "@reach/router";

class NavBar extends Component {
    state = {
        error: "",
    };

    render() {

        return (
            <nav className="NavBar">
                <Link className="NavBar__item" to="/">
                    Home
          </Link>
                <Link className="NavBar__item" to="/resume">
                    Resumé
            </Link>
                <Link className="NavBar__item" to="/projects">
                    Projects
            </Link>
                <Link className="NavBar__item" to="/blog">
                    Blog
            </Link>
                <Link className="NavBar__item" to="/about">
                    About
            </Link>
            </nav >
        );
    }
}

export default NavBar;