import React from 'react';

function ProjectCard({ projectName, projectType, projectTeam, projectDates, projectLocation, projectTech, projectDetail, projectDetail2, projectDetail3, projectFrontEndLink, projectBackEndLink, projectFrontEndRepo, projectBackEndRepo, projectImage, projectVideo, projectVideoTitle, }) {
    return (
        <section className="Project__card">
            <a href={projectFrontEndLink} target="_blank" rel="noopener noreferrer"><h2>{projectName} / {projectType}</h2></a>
            <h3>{projectDates} - {projectLocation}</h3>
            <h3>Project Team:   {projectTeam}</h3>
            <h3>Tech Stack:   {projectTech}</h3>
            {projectVideo.length > 0 &&
                <>
                    <iframe className="Project__video" title={projectVideoTitle} src={projectVideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </>
            }
            <section className="Project__pic_and_details">
                <section className="Project__details_column">
                    <p>{projectDetail}</p>
                    {projectDetail2.length > 0 &&
                        <>
                            <p>{projectDetail2}</p>
                            <br />
                        </>
                    }
                    {projectDetail3.length > 0 &&
                        <>
                            <p>{projectDetail3}</p>
                            <br />
                        </>
                    }
                </section>
                <img className="Project__image" src={require(`../images/${projectImage}`)} alt={`Screenshot of ${projectName}`} />
            </section>
            {projectFrontEndLink.length > 0 &&
                <p>Hosted App:  <a href={projectFrontEndLink} target="_blank" rel="noopener noreferrer">{projectFrontEndLink}</a></p>
            }
            {projectBackEndLink.length > 0 &&
                <p>Hosted Backend:  <a href={projectBackEndLink} target="_blank" rel="noopener noreferrer">{projectBackEndLink}</a></p>
            }
            {projectFrontEndRepo.length > 0 &&
                <p>Front End Repo:  <a href={projectFrontEndRepo} target="_blank" rel="noopener noreferrer">{projectFrontEndRepo}</a></p>
            }
            {projectBackEndRepo.length > 0 &&
                <p>Back End Link:  <a href={projectBackEndRepo} target="_blank" rel="noopener noreferrer">{projectBackEndRepo}</a></p>
            }
        </section>
    );
};

export default ProjectCard;

// src={`src/images/${projectImage}`}